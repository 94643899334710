import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className={`title`}>Coming soon</h1>
  </Layout>
)

export default AboutPage
